import abi from 'src/sdk/blockchain/abi/newPFPAbi.json'
import { NFT } from '../nft'
import { storeToRefs } from 'pinia'
import { usePFPMintStore } from 'src/stores/pfp'
import { useUserInfoStore } from 'src/stores/user'
import { fetchPFPList, fetchPFPSig } from 'src/api'
import { parseUnits } from 'viem'
class PFPNFT extends NFT {
  constructor() {
    super()
    this.setAbi(abi)
  }
  async mint(args, number) {
    const { bindAddressComputed } = storeToRefs(useUserInfoStore())
    const {
      phaseComputed,
      currentPhaseWLComputed,
      currentPriceComputed,
      currentParamPhaseComputed,
      mintInfo,
    } = storeToRefs(usePFPMintStore())

    const { signature, expireTime } = await fetchPFPSig({
      address: bindAddressComputed.value,
      phase: currentParamPhaseComputed.value,
      quantity: number,
    })
    // 第一阶段或重合阶段里面有communityAmount
    if (
      phaseComputed.value === 0 ||
      (phaseComputed.value === 0.5 && mintInfo.value?.communityAmount)
    ) {
      await super.mint({
        functionName: 'mintCommunityNFTs',
        args: [number, mintInfo.value?.communityAmount, expireTime, signature],
        ...args,
      })
    } else {
      // 其他阶段
      let value = +currentPriceComputed.value * number
      await super.mint({
        args: [
          currentParamPhaseComputed.value,
          number,
          currentPhaseWLComputed.value,
          expireTime,
          signature,
        ],
        value,
        ...args,
      })
    }

    return this.fetchNewPFPList(number)
  }
  /**
   *
   * @param {Array<bigInt | string | number>} tokenIds
   * @returns Array<object>
   */
  async tokenIdsToNFTList(tokenIds) {
    const list = await fetchPFPList()
    return tokenIds.map(id => list[Number(id) - 1])
  }
  /**
   * 获取仓库的pfp列表
   * @returns Array<object>
   */
  async fetchMyPFPList() {
    const tokenIds = await this.getTokenIds()
    return this.tokenIdsToNFTList(tokenIds)
  }
  //
  /**
   * 获取最新的数个pfp，用于mint成功后，弹窗调用
   * @param {string | number} number
   * @returns Array<object>
   */
  async fetchNewPFPList(number) {
    const tokenIds = await this.getNewTokenIds({
      number,
    })
    console.log('tokenIds', tokenIds)
    return this.tokenIdsToNFTList(tokenIds)
  }
}

export const pfpNFT = new PFPNFT()
