<script setup>
import { ref } from 'vue'
import lightBg from 'src/asset/nftCards/bg_active.webp'

const emit = defineEmits(['buy'])
const props = defineProps({
  data: Object,
})

function handleBuy() {
  emit('buy', props.data.level)
}
</script>
<template>
  <div class="box px-7px relative w-full">
    <div
      class="flex pt-12px flex-col flex-items-center -mt-10px w-full border-1.5px border-solid border-#59443E bg-#090603"
    >
      <div class="w-full relative px-12px">
        <img class="w-full min-h-246px" :src="lightBg" alt="" />
        <img
          class="w-81% absolute top-0 left-50% -translate-x-50%"
          :src="data.image"
          alt=""
        />
      </div>
      <div
        class="bg-item relative w-full h-256px pt-11px mt-13px flex flex-col flex-items-center border border-x-0 border-solid border-#59443E"
      >
        <div
          class="desc tracking-2px fz-2 5xl:scale-130 4xl:scale-110 3xl:scale-100 2xl:scale-90 xl:scale-80 md:scale-70 origin-tc w-326px mx-6.5 text-#856E55 tracking-1.5px"
          v-html="data.desc"
        ></div>
        <div
          v-if="data.id === 0 && data.roleId"
          @click="handleBuy"
          :class="data.isClaim ? 'btn-disabled' : 'btn-buy'"
          class="mouse_pointer fz-3 w-full h-40px 5xl:h-50px -5xl:mb-40px absolute bottom-0 left-0 flex-center border border-solid border-#C9771F text-#FFE1A1 tracking-2.4px"
        >
          {{ data.isClaim ? 'Unlocked Transmog' : 'Unlock Transmog' }}
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.box {
  background: linear-gradient(
    180deg,
    rgba(0 0 0 / 100%) 0%,
    rgba(6 4 4 / 63%) 86.43%,
    rgba(28 24 23 / 63%) 100%
  );

  .bg-item {
    background: linear-gradient(180deg, #331e0a 0%, #1b1208 100%);
  }

  .bg-name {
    @include bgAuto('src/asset/immutableIncome/bg_item.webp');
  }

  .bg-num {
    @include bgAuto('src/asset/immutableIncome/bg_num.webp');
  }

  .btn-buy {
    background: linear-gradient(90deg, #9b4c29 0%, #a55e2e 100%);

    &:hover {
      background: linear-gradient(90deg, #c3653c 0%, #d78b39 100%);
    }
  }

  .btn-disabled {
    color: #241609 !important;
    background: linear-gradient(90deg, #ffe1a1 0%, #ffe1b1 100%);
  }

  .desc {
    ::v-deep {
      div {
        margin-bottom: 4%;
      }
    }
  }
}
</style>
