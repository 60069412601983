<script setup>
import { storeToRefs } from 'pinia'
import { ref, computed } from 'vue'
import Lucky from './Lucky'
import NFT from './NFT'
import SoulCard from './SoulCard'
import Income from './Income'
import Benefit from './Benefit'
import Popover from 'src/components/Popover'
import { useMediaStore } from 'src/stores/media'
import { useBoundingClientRect } from 'src/hooks/useBoundingClientRect'

const { min2XL } = storeToRefs(useMediaStore())
const triggerDomRef = ref(null)
const triangleDomRef = ref(null)
const { dataRef: triggerDataRef } = useBoundingClientRect(triggerDomRef)
const { dataRef: triangleDataRef } = useBoundingClientRect(triangleDomRef)
const triangleComputed = computed(() => {
  return {
    left: min2XL.value
      ? 193 / 2 + triangleDataRef.value.width / 2 + 'px'
      : triggerDataRef.value.width / 2 - triangleDataRef.value.width / 2 + 'px',
  }
})
const softPledgeShow = import.meta.env.VITE_SOFT_PLEDGE_SHOW
const turntableShow = import.meta.env.VITE_TURNTABLE_SHOW
const immutableIncomeShow = import.meta.env.VITE_IMMUTABLE_INCOME_SHOW
const soulCardMintShow = import.meta.env.VITE_SOULCARD_MINT_SHOW
</script>

<template>
  <Popover>
    <template #trigger>
      <div ref="triggerDomRef">
        <slot></slot>
      </div>
    </template>
    <div class="box">
      <!-- <Benefit class="mt-1.5"></Benefit> -->
      <SoulCard v-if="soulCardMintShow"></SoulCard>
      <Income v-if="immutableIncomeShow"></Income>
      <NFT v-if="softPledgeShow"></NFT>
      <Lucky v-if="turntableShow"></Lucky>
      <div
        ref="triangleDomRef"
        class="triangle mt-1.5"
        :style="triangleComputed"
      ></div>
    </div>
  </Popover>
</template>

<style scoped lang="scss">
.box {
  // @include bg100('src/asset/events/box_bg.webp');
  @apply relative pb-2 pt-1.5 px-1.75 bg-#715535 border border-solid border-#B46E2A rounded-5px;

  // w-193px h-134px
  // &.not_softPledge {
  //   @apply h-50px;
  // }

  .triangle {
    @include triangle(6px, #ecc355);
    @apply -bottom-10px absolute translate-x-[-100%];
  }
}
</style>
