<script setup>
import { onMounted, onUnmounted, ref, watchEffect } from 'vue'
import { useMusicStore } from 'src/stores/music'
import { storeToRefs } from 'pinia'
import { hasPhone } from 'src/stores/media'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import BGM from './components/BGM.vue'
import OpenBGM from './components/OpenBGM.vue'
import Music from './components/Music.vue'
import Model from './components/Model/index.vue'
import Content from './components/Content.vue'
import Footer from './components/Footer.vue'
import Trailer from './components/Trailer'
import ActionSwitch from './components/ActionSwitch'
import Souls from './components/Souls'
import Gallery from './components/Gallery'
import Test3RD from './components/Test3RD'
import { wait } from '@BOGX/utils'
import { getElasticRolling } from './components/utils'

// import Connect from './components/Connect'
import SignTriangle from './components/SignTriangle'
import XLogo from './components/XLogo'
import PhoneXLogo from './components/PhoneXLogo'
import FooterBG from './components/FooterBG'
import { controlDirection } from './components/Model/controlDirection'

const pfpShow = import.meta.env.VITE_PFP_SHOW
const galleryShow = import.meta.env.VITE_GALLERY_SHOW
const mintShow = import.meta.env.VITE_MINT_START
const musicStore = useMusicStore()
const { progressLoadingCompletedAudioPlay, bgmAudioPlay } = musicStore
const { oneOpenBGM } = storeToRefs(musicStore)
const switchActiveRef = ref(false)
const modelRef = ref(null)
const footerRef = ref({})
const controlDirectionRef = ref(null)
let controlDirectionAvailable = false
// let controlDirection
function handleActivate() {
  const animationGroup = modelRef.value?.getAnimationGroup()
  if (!animationGroup.active) {
    modelRef.value?.handleActivate()
    const stop = watchEffect(() => {
      switchActiveRef.value = true
      if (modelRef.value?.progress === 1 && !animationGroup.active) {
        stop()
        progressLoadingCompletedAudioPlay()
        switchActiveRef.value = false
      }
    })
  }
}
function handleDeviceorientation(event) {
  controlDirection._handleEventListener(event)
}
function handleContainerClick() {
  oneOpenBGM.value && bgmAudioPlay()
  if (!controlDirectionAvailable && hasPhone) {
    controlDirectionAvailable = true
    if (
      typeof DeviceOrientationEvent !== 'undefined' &&
      typeof DeviceOrientationEvent.requestPermission === 'function'
    ) {
      DeviceOrientationEvent.requestPermission()
        .then(permissionState => {
          if (permissionState === 'granted') {
            window.addEventListener(
              'deviceorientation',
              handleDeviceorientation,
            )
          }
        })
        .catch(console.error)
    } else {
      // handle regular non iOS 13+ devices
      console.log('not iOS')
      window.addEventListener('deviceorientation', handleDeviceorientation)
    }
  }
}
function getEnterPage(val) {
  console.log(val)
  if (val) {
    modelRef.value?.noneModel()
  } else {
    modelRef.value?.showModel()
  }
}
async function handleAbout() {
  if (footerRef.value && !footerRef.value.oneEnter) {
    footerRef.value.oneEnter = true
    await wait(500)
  }
  getElasticRolling()?.goToBottom()
}
onMounted(() => {
  if (hasPhone) {
    if (
      typeof DeviceOrientationEvent !== 'undefined' &&
      typeof DeviceOrientationEvent.requestPermission === 'function'
    ) {
      DeviceOrientationEvent.requestPermission()
        .then(permissionState => {
          if (permissionState === 'granted') {
            controlDirectionAvailable = true
            window.addEventListener(
              'deviceorientation',
              handleDeviceorientation,
            )
          }
        })
        .catch(console.error)
    } else {
      // handle regular non iOS 13+ devices
      console.log('not iOS')
      controlDirectionAvailable = true
      window.addEventListener('deviceorientation', handleDeviceorientation)
    }
  }
})
onUnmounted(() => {
  ScrollTrigger.killAll()
  if (hasPhone) {
    controlDirection.unload()
    window.removeEventListener('deviceorientation', handleDeviceorientation)
  }
})
</script>

<template>
  <div @click="handleContainerClick">
    <Music></Music>
    <div>
      <div
        v-if="!hasPhone"
        class="pointer-events-none fixed bottom-0 left-0 z-2 h-150px w-full model_bg_footer"
      ></div>
      <Footer ref="footerRef" @update:enterPage="getEnterPage"></Footer>
      <!-- <About></About> -->
      <SignTriangle
        :scroll-trigger-dom="footerRef.scrollTriggerDom"
      ></SignTriangle>
      <
      <template v-if="!hasPhone">
        <Trailer :scroll-trigger-dom="footerRef.scrollTriggerDom"></Trailer>
        <ActionSwitch
          :active="switchActiveRef"
          :scroll-trigger-dom="footerRef.scrollTriggerDom"
          :progress="modelRef?.progress"
          @click="handleActivate"
        ></ActionSwitch>
        <!-- <Souls :scroll-trigger-dom="footerRef.scrollTriggerDom"></Souls> -->
        <Souls></Souls>
        <Test3RD v-if="mintShow"></Test3RD>
        <Gallery v-if="pfpShow && galleryShow"></Gallery>
      </template>

      <div v-if="footerRef.scrollTriggerDom" class="wrapper body-bg">
        <Model
          ref="modelRef"
          class="model"
          :control-direction="controlDirectionRef"
          :scroll-trigger-dom="footerRef.scrollTriggerDom"
        ></Model>
        <FooterBG v-if="hasPhone">
          <PhoneXLogo
            :progress="modelRef?.progress"
            :scroll-trigger-dom="footerRef.scrollTriggerDom"
            @activate="handleActivate"
          ></PhoneXLogo>
        </FooterBG>
        <Content :scroll-trigger-dom="footerRef.scrollTriggerDom"></Content>
      </div>
      <template v-if="!hasPhone">
        <XLogo
          v-if="footerRef.scrollTriggerDom"
          :scroll-trigger-dom="footerRef.scrollTriggerDom"
          @about="handleAbout"
        ></XLogo>

        <BGM class="floating_button fixed bottom-[100px] right-[20px]"></BGM>
      </template>
    </div>
    <template v-if="!hasPhone">
      <OpenBGM v-if="oneOpenBGM" class="pointer-events-none z-2001"></OpenBGM>
    </template>
  </div>
</template>

<style scoped lang="scss">
.floating_button {
  @apply z-[2];
  @extend .mouse_pointer;
}

.wrapper {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.model_bg_footer {
  background: linear-gradient(transparent, rgb(0 0 0 / 70%));
}

.model {
  position: absolute;
  top: 0;
  display: block;
  width: 100%;
  height: 100%;
}
</style>
