import { storeToRefs } from 'pinia'
import {
  addressesRef,
  inconsistentBindAddressErrorText,
  hasBlockchainLogin,
  getSign,
  verifySignature,
  accountRef,
} from 'src/sdk/blockchain'
import { fetchSignMessage } from 'src/api'
import { useUserInfoStore } from 'src/stores/user'
import { getAddress } from 'viem'
import { mainnet } from 'viem/chains'
import { signatureDataStorage } from '../store'
import { NFP_TYPE, NFP_VALUE } from 'src/constant'

export async function getUserBindAddress() {
  const { bindAddressComputed } = storeToRefs(useUserInfoStore())
  await hasBlockchainLogin()
  const addresses = addressesRef.value
  const account = addresses.find(
    account => getAddress(account) === bindAddressComputed.value,
  )
  console.log('account', account, addressesRef.value)
  if (account) {
    return account
  } else {
    // return addresses
    throw new Error(inconsistentBindAddressErrorText)
  }
}

export async function getUserAddress() {
  const { bindAddressComputed } = storeToRefs(useUserInfoStore())
  await hasBlockchainLogin()
  const addresses = addressesRef.value
  const account = addresses.find(
    account => getAddress(account) === bindAddressComputed.value,
  )
  console.log('account', account, addressesRef.value)
  if (account) {
    return account
  } else {
    return addresses
  }
}

export async function getWalletSign() {
  if (!(await hasBlockchainLogin())) return false
  const address = getAddress(accountRef.value?.address)
  let web3Signed = signatureDataStorage.getItem(address)
  if (!web3Signed) {
    const { signMessage } = await fetchSignMessage()
    web3Signed = await getSign(signMessage)
    signatureDataStorage.setItem(address, web3Signed)
  }
  return web3Signed
}

export async function getBaseWalletSign(accountAddress, chainId = mainnet) {
  const address = getAddress(accountAddress)
  let web3Signed = signatureDataStorage.getItem(address)
  if (!web3Signed) {
    const { signMessage } = await fetchSignMessage()
    web3Signed = await verifySignature({
      accountAddress,
      chainId,
      message: signMessage,
    })
    signatureDataStorage.setItem(address, web3Signed)
  }
  return web3Signed
}
export function attributesToJson(attributes) {
  const result = {}
  attributes.forEach(item => {
    result[item[NFP_TYPE]] = item[NFP_VALUE]
  })
  return result
}

export function splitNFTName(nftName) {
  const match = nftName.match(/^(.*)\s#(\d+)$/)
  if (match) {
    return {
      name: match[1].trim(), // 前面的文字
      number: match[2], // 后面的 # 数字
    }
  } else {
    return {
      name: nftName, // 前面的文字
      number: '', // 后面的 # 数字
    }
  }
}

export function getImmutableInfo(chainId) {
  const hasProd = chainId
    ? chainId === 13371
    : import.meta.env.VITE_MODE === 'prod'
  return {
    chainId: hasProd ? 13371 : 13473,
    chainName: hasProd ? 'imtbl-zkevm-mainnet' : 'imtbl-zkevm-testnet',
  }
}
