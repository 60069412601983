import { Store, StoreData } from '@abmao/store'

export const pfpListStore = new Store('pfpListStore', {
  expiry: import.meta.env.VITE_PFP_LIST_EXPIRY,
})

export const DiscordCodeDataStore = new Store('ThirdPartyDiscordCodeData')
export const DiscordTokenDataStore = new Store('ThirdPartyDiscordTokenData')

export const homeEventseDataStorage = new Store('homeEventsData')

const signatureParams = {
  expiry: 900000, // 15分钟
}

export const signatureDataStorage = new StoreData('signature', signatureParams)
