<script setup>
import closeImg from 'src/asset/close.webp'
const emit = defineEmits(['close'])
const props = defineProps(['url'])
function handleClose() {
  emit('close')
}
window.props = props
</script>
<template>
  <div class="poster pt-2.5" :style="{ backgroundImage: `url(${url})` }">
    <img
      :src="closeImg"
      class="w-12px absolute right-1 top-2 mouse_pointer"
      @click.stop="handleClose"
    />
    <slot></slot>
  </div>
</template>

<style scoped>
.poster {
  @apply w-281px h-195px relative;

  background-repeat: no-repeat;
  background-size: 100% 100%;
}
</style>
