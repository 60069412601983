<script setup>
import { computed, ref } from 'vue'
import { usePhaseStatus } from 'src/pages/PFP/hooks/phaseStatus'
import { calculateLineProperties } from '@BOGX/utils'
import { useMediaStore } from 'src/stores/media'
import { storeToRefs } from 'pinia'
const props = defineProps({
  phase: Number,
  lineTargeDom: Object,
})
const phaseComputed = computed(() => props.phase)
const { ingComputed } = usePhaseStatus(phaseComputed)
const lianDomRef = ref(null)
const hengDomRef = ref(null)
const hengStyleComputed = computed(() => {
  if (hengDomRef.value && props.lineTargeDom) {
    const lianData = lianDomRef.value.getBoundingClientRect()
    const lineTargeData = props.lineTargeDom.getBoundingClientRect()
    const { length, angle } = calculateLineProperties(
      lianData.right,
      lianData.top,
      lineTargeData.right - lineTargeData.width * 0.135,
      lineTargeData.top,
    )
    return {
      width: length + 'px',
      transform: `rotate(${angle}deg)`,
    }
  }
})
const { min2XL } = storeToRefs(useMediaStore())
</script>
<template>
  <div
    :class="`${ingComputed ? 'ing' : 'end'}_bg ${min2XL ? 'box_2xl' : 'box_md'}`"
    class="2xl:px-3.5 2xl:pt-3 md:px-2 md:pt-1.5 relative"
  >
    <!-- <div class="absolute z-2 top-55px" v-if="ingComputed">
      <div class="line heng" ref="hengDomRef"></div>
      <div class="line lian" ref="lianDomRef" :style="hengStyleComputed"></div>
    </div> -->

    <slot></slot>
  </div>
</template>

<style scoped lang="scss">
.ing_bg {
  @include bg100('src/asset/PFP/mint/stage_bg.webp');
}

.end_bg {
  @include bg100('src/asset/PFP/mint/stage_bg_end.webp');
}

.heng {
  transform: rotate(180deg);
  transform-origin: left;
}

.lian {
  transform-origin: left;
}

.box_2xl {
  .ing_bg {
    @apply w-509px h-233px;
  }

  .end_bg {
    @apply w-509px h-159px;
  }

  .heng {
    @apply w-60px;

    left: -15px;
  }

  .lian {
    left: -75px;
  }
}

.box_md {
  .ing_bg {
    @apply w-433px h-198px;
  }

  .end_bg {
    @apply w-433px h-135px;
  }

  .heng {
    @apply w-50px;

    left: -11px;
  }

  .lian {
    left: -61px;
  }
}

.line {
  @apply border-0 border-dashed border-#b0682a border-t absolute pointer-events-none;
}
</style>
