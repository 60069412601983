<script setup>
import { computed } from 'vue'
import dayjs from 'dayjs'
import { storeToRefs } from 'pinia'
import { usePFPMintStore } from 'src/stores/pfp'
import { useMediaStore } from 'src/stores/media'
const { mintInfo } = storeToRefs(usePFPMintStore())
const startDayComputed = computed(() => {
  const startDay = mintInfo.value?.gtdTime?.[0]
  if (startDay) {
    return dayjs(startDay * 1000).format('YYYY.MM.DD')
  }
})
const { min2XL } = storeToRefs(useMediaStore())
</script>
<template>
  <div
    class="box flex-center fz-3 color-#565454"
    :class="`${min2XL ? 'box_2xl' : 'box_md'}`"
  >
    <span>The official minting has started at: {{ startDayComputed }}</span>
  </div>
</template>
<style scoped lang="scss">
.box {
  @include bg100('src/asset/PFP/mint/stage1_bg_end.webp');
}

.box_2xl {
  @apply w-509px h-55px;
}

.box_md {
  @apply w-433px h-47px;
}
</style>
