<script setup>
import { ref, computed } from 'vue'
import prompt_arrow from 'src/asset/mint/prompt_arrow.webp'
import { hasPhone } from 'src/stores/media'
import { useMediaStore } from 'src/stores/media'
import { storeToRefs } from 'pinia'
import Video from 'src/components/Video'
import VideoPoster from 'src/asset/soulCardMint/video_poster.webp'

const props = defineProps({
  supply: Number,
})

const emit = defineEmits(['openCardsModal'])

const soulCardVideo =
  'https://voidlabs.bladeofgod.net/webres/static/soulCard_introduction.mp4'

// const total = computed(() => props.supply)
const { min2XL } = storeToRefs(useMediaStore())
</script>

<template>
  <div class="left-mask pl-107px pt-44px">
    <div class="flex flex-col flex-items-start">
      <img class="w-556px" src="/src/asset/soulCardMint/Logo.webp" alt="" />
      <div class="family_blk mt-9px text-#fff fz-8">COLLECTION</div>
      <div
        class="family_blk mt-10px pl-8px w-320px h-42px text-#fff bg-#0E0E0F border border-solid border-#696B6B/60"
        :class="`fz-${min2XL ? 5 : 4}`"
      >
        TOTAL SUPPLY:<span class="text-#9668F4 leading-45px ml-1.5">{{
          2000
        }}</span>
      </div>
      <div
        class="video-bg relative pt-2px pb-2px px-3px w-492px h-185px mt-13.5px overflow-hidden"
      >
        <Video
          class="w-full h-full"
          :src="soulCardVideo"
          :poster="VideoPoster"
          :controls="false"
          preload="auto"
          autoplay
          loop
          muted
        ></Video>
      </div>

      <div class="text-bg w-492px h-62.5px px-2 py-2">
        Card drop rates: <span class="text-#A37CF4">60%</span> Epic,
        <span class="text-#A37CF4">35%</span> Legendary,
        <span class="text-#A37CF4">5%</span> Primal;
        <div
          @click="emit('openCardsModal')"
          class="mouse_pointer text-#A37CF4 decoration-underline"
        >
          Click to view all Soul Cards for this Mint.
        </div>
      </div>

      <div class="round-bg px-10px pt-1 mt-4 w-492px h-89px text-#fff">
        <div>How can I transfer USDC to Immutable zkEVM?</div>
        <div class="text-#AA81FF">
          Please use the Immutable Bridge or CEX Deposit to transfer USDC from
          other EVM chains or exchanges to Immutable zkEVM.
        </div>
      </div>
      <div class="mt-4 text-#AA81FF">
        <div>Designed by</div>
        <img class="mt-8px w-132px" src="/src/asset/labs_logo.webp" alt="" />
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.left-mask {
  @include bg100('/src/asset/soulCardMint/Left_mask.webp');
}

.round-bg {
  @include bg100('/src/asset/soulCardMint/Round_BG.webp');
}

.video-bg {
  @include bg100('/src/asset/soulCardMint/video_bg.webp');
}

.text-bg {
  @include bg100('/src/asset/soulCardMint/text_bg.webp');
}

.video-js .vjs-tech {
  object-fit: fill !important;
}
</style>
