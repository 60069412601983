<script setup>
import { NTooltip, NPopover } from 'naive-ui'

const props = defineProps({
  data: {
    type: Object,
    default() {
      return {}
    },
  },
})

const tooltipTheme = {
  color: '#2C2F35', // 设置背景颜色
  arrowHeight: '10px', // 箭头高度
  arrowWidth: '10px', // 箭头宽度
}
</script>

<template>
  <div class="py-5 flex justify-between">
    <div></div>
    <div class="fz-3 pr-26px flex flex-items-center">
      <div class="flex flex-items-center">
        <span>Soul Gem</span>
        <img
          class="ml-2 -mt-1 w-39.5px h-38px z-1"
          src="src/asset/nftCards/vbogx.webp"
          alt=""
        />
        <div
          class="coin-bg relative px-2.5 z-0 w-131px h-27px leading-27px text-right text-#EED095"
        >
          {{ data?.v_bo_gx_count || 0 }}
        </div>
      </div>
      <div class="relative mx-8 flex flex-items-center">
        <span>$tBOGX</span>
        <img
          class="ml-2 -mt-1 w-29.5px h-37.5px z-1"
          src="src/asset/nftCards/tbogx.webp"
          alt=""
        />
        <div
          class="coin-bg relative px-2.5 z-0 w-131px h-27px leading-27px text-right text-#EED095"
        >
          {{ data?.boGxCount || 0 }}
        </div>
      </div>
      <NPopover
        class="GrayPopover"
        arrow-class=".GrayPopover-arrow-class"
        placement="bottom-end"
        :theme-overrides="tooltipTheme"
      >
        <template #trigger>
          <img
            class="mouse_pointer w-28.5px"
            src="src/asset/nftCards/introduction1.webp"
            alt=""
          />
        </template>
        <div class="fz-3 flex-col">
          <ul class="w-700px py-3 px-4 text-#FFFFFF flex-col tracking-1.5px">
            <li class="mb-4">
              1.Minting $tBOGX:
              <div class="text-#899CA5">
                When Soul Gems are available, they will be automatically
                consumed to mint $tBOGX. If collecting $tBOGX fails due to
                network issues or other reasons, you can immediately attempt to
                collect again.
              </div>
            </li>
            <li class="mb-4">
              2.Minting Requirements:
              <div class="text-#899CA5">
                An Immutable account must be linked.
              </div>
            </li>
            <li>
              3.Minting Rate:
              <div class="text-#899CA5">
                Owning Genesis, Ethereal, partner privileges, or using a Minting
                Acceleration Coupon can increase the minting rate. A higher
                minting rate reduces the time required to mint $tBOGX.
              </div>
            </li>
          </ul>
        </div>
      </NPopover>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.coin-bg {
  margin-left: -25px;

  @include bg100('src/asset/nftCards/small_box.webp');
}
</style>
