<script setup>
import { onMounted, ref, onBeforeUnmount } from 'vue'
import lottie from 'lottie-web'

const props = defineProps({
  animationData: {
    type: Object,
    required: true,
  },
  loop: {
    type: Boolean,
    default: true,
  },
  autoplay: {
    type: Boolean,
    default: true,
  },
})

const lottieContainer = ref(null)
let lottieInstance = null

onMounted(() => {
  lottieInstance = lottie.loadAnimation({
    container: lottieContainer.value, // 渲染动画的 DOM 元素
    animationData: props.animationData, // 传入的 Lottie 动画 JSON 数据
    renderer: 'svg', // 使用 SVG 渲染
    loop: props.loop, // 是否循环播放
    autoplay: props.autoplay, // 是否自动播放
  })
})

onBeforeUnmount(() => {
  if (lottieInstance) {
    lottieInstance.destroy() // 组件卸载时销毁动画实例
  }
})
</script>

<template>
  <div ref="lottieContainer" class="lottie-container"></div>
</template>

<style scoped>
.lottie-container {
  width: 100%;
  height: 100%;
}
</style>
