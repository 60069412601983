import { createApp } from 'vue'
import { createPinia } from 'pinia'
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate'
import 'normalize.css/normalize.css'
import 'uno.css'
import './styles/variables.css'
import './styles/global.css'
import './styles/global.scss'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import { gsap } from 'gsap'
import { MotionPathPlugin } from 'gsap/MotionPathPlugin'
import { router } from './router'
import App from './App'
import { init as blockchainInit, walletInit } from './sdk/blockchain'
import 'virtual:svg-icons-register'
import { useDirectives } from 'src/directives'
import { ipWhitelistInit } from './helpers/ip'
const app = createApp(App)
walletInit()
blockchainInit()

// 关于 tailwind 的 preflight 样式
const meta = document.createElement('meta')
meta.name = 'naive-ui-style'
document.head.appendChild(meta)

// 优先级比naive-ui高
import('./styles/index.scss')

gsap.registerPlugin(ScrollTrigger, MotionPathPlugin)

const pinia = createPinia()
pinia.use(piniaPluginPersistedstate)
app.use(router).use(pinia)

app.mount('#app')
useDirectives(app)
if (import.meta.env.VITE_IP_WHITE_LIST) {
  console.log('1')
  ipWhitelistInit()
}
