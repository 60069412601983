import { usePFPMintStore } from 'src/stores/pfp'
import { storeToRefs } from 'pinia'
import { computed } from 'vue'

export function usePhaseStatus(immutablePhaseValue) {
  const { phaseComputed } = storeToRefs(usePFPMintStore())
  const phaseStatusComputed = computed(() => {
    const currentPhase = phaseComputed.value
    const phase = immutablePhaseValue.value

    if (currentPhase === 0.5) {
      if (phase === 0 || phase === 1) {
        return 2
      } else if (phase > currentPhase) {
        return 1
      } else if (phase === currentPhase) {
        return 2
      } else {
        return 3
      }
    } else {
      if (phase > currentPhase) {
        return 1
      } else if (phase === currentPhase) {
        return 2
      } else {
        return 3
      }
    }
  })
  const ingComputed = computed(() => phaseStatusComputed.value === 2)
  return {
    phaseStatusComputed,
    ingComputed,
  }
}
