import { writeContract, hasBlockchainLogin } from 'src/sdk/blockchain'
import {
  BaseBlockchain,
  baseBlockchainParams,
  blockchainBalanceFees,
  blockchainBindAddress,
  commonBlockchainParams,
  useDecorator,
} from './blockchain1'
import {
  baseGetNewTokenId,
  baseGetTokenids,
  baseGetNewTokenIds,
} from 'src/sdk/blockchain'

export class SoulJadeStore extends BaseBlockchain {
  /**
   * mint
   */
  async mint(args) {
    await hasBlockchainLogin()
    return writeContract(args)
  }
  /**
   * 获取合约某个可读属性
   */
  getTotalMintCount(args) {
    return this.getContractItemData({
      functionName: 'totalSupply',
      ...args,
    })
  }
  /**
   * 获取所有tokenid
   */
  getTokenIds(args) {
    return baseGetTokenids(args)
  }
  /**
   * 获取最新的数个tokenid
   */
  getNewTokenIds(args) {
    return baseGetNewTokenIds(args)
  }
  /**
   * 获取最新的tokenid
   */
  getNewTokenId(args) {
    return baseGetNewTokenId(args)
  }
}
const SoulJadeProty = SoulJadeStore.prototype
useDecorator(SoulJadeProty, 'mint', blockchainBalanceFees)
useDecorator(SoulJadeProty, 'mint', baseBlockchainParams)
useDecorator(SoulJadeProty, 'getTokenIds', baseBlockchainParams)
useDecorator(SoulJadeProty, 'getNewTokenIds', baseBlockchainParams)
useDecorator(SoulJadeProty, 'getNewTokenId', baseBlockchainParams)
useDecorator(SoulJadeProty, 'mint', commonBlockchainParams)
useDecorator(SoulJadeProty, 'getTokenIds', commonBlockchainParams)
useDecorator(SoulJadeProty, 'getNewTokenIds', commonBlockchainParams)
useDecorator(SoulJadeProty, 'getNewTokenId', commonBlockchainParams)
useDecorator(SoulJadeProty, 'getTotalMintCount', commonBlockchainParams)
