<script setup>
import ItemModal from 'src/components/ItemModal'
import { useItemTransfer } from 'src/hooks/useItemTransfer'

const props = defineProps({
  show: Boolean,
  UserID: [String, Number],
  selectData: {
    type: Array,
    default() {
      return []
    },
  },
})
const emit = defineEmits(['update:show', 'success', 'error'])
const { isLoading: submitLoading, execute } = useItemTransfer()
function handleClose() {
  emit('update:show', false)
}
async function handleConfirm() {
  try {
    await execute(0, props.selectData, props.UserID)
    emit('success', props.selectData)
    handleClose()
  } catch (error) {
    console.log(error)
    emit('error', error)
  }
}
</script>

<template>
  <ItemModal
    :show="props.show"
    confirm-text="Transfer"
    :loading="submitLoading"
    width="425px"
    @update:show="handleClose"
    @cancel="handleClose"
    @confirm="handleConfirm"
  >
    <div class="fz-4 px-16 py-5 text-center text-#CFDDE7">
      Are you sure you want to transfer this item?
    </div>
  </ItemModal>
</template>
