import { useMessage } from 'naive-ui'
import { storeToRefs } from 'pinia'
import { useAsyncState } from '@vueuse/core'
import { useLoginStore } from 'src/stores/user'
import { fetchSaleListInfo, fetchCreateSoulCard } from 'src/api'
import { errorCodeToMessage } from 'src/helpers/utils'
import eggImg from 'src/asset/PFP/egg.webp'
import {
  getBlockchainErrorCode,
  getBlockchainNeedErrorMessage,
} from 'src/sdk/blockchain'
import { soulCard } from 'src/helpers/blockchain'
import { usePFPMintStore } from 'src/stores/pfp'
import { computed, ref } from 'vue'

export function useMint() {
  const pfpStore = usePFPMintStore()
  const { open: login } = useLoginStore()
  const { hasLogin } = storeToRefs(useLoginStore())
  const message = useMessage()
  const { currentPhaseWLComputed } = storeToRefs(pfpStore)
  const quantityRef = ref(1)
  const mintRef = ref(null)
  const quantityMaxComputed = computed(() =>
    currentPhaseWLComputed.value > 5 ? 5 : currentPhaseWLComputed.value,
  )
  const successShowRef = ref(false)
  const errorShowRef = ref(false)
  const checkoutModalRef = ref(false)
  const cardsShowRef = ref(false)
  const mintListRef = ref([])
  const widgetTypeRef = ref('')
  const orderDataRef = ref({})
  const walletAddress = ref('')
  const productsListRef = ref([])

  const { execute: executeProducts } = useAsyncState(
    async () => {
      const data = await fetchSaleListInfo({
        web_type: 'soulCard',
      })
      const extraInfo = {
        picture: eggImg,
      }
      orderDataRef.value = {
        ...data,
        ...extraInfo,

        contract_address: '0x095bDf251bEE0C9462f0c2B1FF5ddd78bB401480',
        id: 2,
        pricing: [
          {
            amount: 0,
            currency: 'USDC',
            id: 5,
            product_id: 2,
            currency_type: 'crypto',
            currency_address: null,
          },
          {
            amount: 0,
            currency: 'ETH',
            id: 6,
            product_id: 2,
            currency_type: 'crypto',
            currency_address: null,
          },
          {
            amount: 0,
            currency: 'USD',
            id: 7,
            product_id: 2,
            currency_type: 'fiat',
            currency_address: null,
          },
        ],
      }
      console.log(data, orderDataRef.value)
    },
    null,
    {
      immediate: false,
      onError(error) {
        console.log('error', error)
        errorCodeToMessage(error)
      },
    },
  )

  function handleConnect() {
    if (walletAddress.value) {
      widgetTypeRef.value = 'wallet'
    } else {
      widgetTypeRef.value = 'connect'
    }
    checkoutModalRef.value = true
  }

  function getWalletAddress(wallet) {
    walletAddress.value = wallet
  }

  function handleMint() {
    if (!hasLogin.value) {
      login()
      return
    }
    if (!walletAddress.value) {
      widgetTypeRef.value = 'connect'
      checkoutModalRef.value = true
      return
    }
    orderDataRef.value.qty = quantityRef.value
    widgetTypeRef.value = 'sale'
    checkoutModalRef.value = true
  }

  async function handleMintSuccess(list) {
    successShowRef.value = true
    // mintListRef.value = list
    quantityRef.value = 0
    const listCalls = list.map(tokenId => {
      return fetchCreateSoulCard({
        tokenId,
        walletaddr: walletAddress,
      })
    })
    const data = await Promise.all(listCalls)
    const soulCardList = await soulCard.tokenIdsToNFTList(list)
    mintListRef.value = soulCardList
    console.log('handleMintSuccess', list, soulCardList)
  }
  function handleMintError(list, error) {
    const code = getBlockchainErrorCode(error)
    if (code === -1) {
      errorShowRef.value = true
      mintListRef.value = list
    } else {
      message.error(getBlockchainNeedErrorMessage(error))
    }
    console.log('handleMintError', list, error, code)
  }

  executeProducts()

  return {
    quantityRef,
    checkoutModalRef,
    successShowRef,
    errorShowRef,
    cardsShowRef,
    mintListRef,
    mintRef,
    productsListRef,
    widgetTypeRef,
    orderDataRef,
    quantityMaxComputed,
    handleMintSuccess,
    handleMintError,
    handleConnect,
    handleMint,
    getWalletAddress,
  }
}
