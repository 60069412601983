<script setup>
import Modal from 'src/components/Modal'
import { storeToRefs } from 'pinia'
import { useBindEmailStore, useUserInfoStore } from 'src/stores/user'
import { desensitizationEmail } from '@BOGX/utils'
import WalletItem from '../WalletItem'
import UserAccName from '../UserAccName'
import UserInfo from './UserInfo.vue'
import LinkAccount from './LinkAccount'
import PasswordItem from './PasswordItem.vue'

const props = defineProps({
  show: Boolean,
})
const emit = defineEmits(['update:show'])
const { userInfo } = storeToRefs(useUserInfoStore())
const { open } = useBindEmailStore()
function handleClose(value = false) {
  emit('update:show', value)
}
</script>

<template>
  <Modal :show="props.show" transform-origin="top" @update:show="handleClose">
    <div class="modal_box pb-[8px]">
      <span class="modal_triangle_top"></span>
      <div class="item_title_bar gray2-color fz-5 mb-[8px]">PROFILE</div>
      <ul class="item-ul px-2 max-h-80vh overflow-y-auto">
        <li class="item-li">
          <UserAccName></UserAccName>
        </li>
        <li
          class="item-li relative flex items-center"
          @click="userInfo?.mail || open()"
        >
          <img
            src="/src/asset/mail.webp"
            alt=""
            class="h-13px align-middle md:h-[20px]"
          />
          <span class="absolute-center gray2-color">{{
            desensitizationEmail(userInfo?.mail) || '--'
          }}</span>
        </li>
        <li class="item-li">
          <PasswordItem></PasswordItem>
        </li>
        <UserInfo></UserInfo>
        <!-- TODO 第三方 -->
        <li class="item-li item-li-not-hover h-a! cursor-auto!">
          <LinkAccount></LinkAccount>
        </li>
        <li class="item-func-li" @click="handleClose(false)">Back</li>
      </ul>
    </div>
  </Modal>
</template>

<style scoped lang="scss">
@import '../ul';
</style>
