<script setup>
import { ref, watch } from 'vue'
import Header from './components/Header/index.vue'
import Card from './components/Card/index'
import RolesModal from './components/RolesModal/index'
import SuccessModal from './components/SuccessModal/index'
import History from './components/History/index.vue'
import HistoryModal from './components/History/HistoryModal.vue'
import { storeToRefs } from 'pinia'
import { useImmutableIncome } from '.'
import { useMessage, NSelect, NFormItem } from 'naive-ui'
import { getCurrentProvider } from 'src/sdk/blockchain/walletConnect'

const showSuccessModal = ref(false)

const {
  productsList,
  roleList,
  roleId,
  roleName,
  successList,
  showRoleModal,
  handleSelectHero,
  handleConfirm,
  handleBuy,
  handleSuccess,
} = useImmutableIncome()

const showHistoryModal = ref(false)
function openHistoryModal() {
  showHistoryModal.value = true
}

const selectTheme = {
  peers: {
    InternalSelection: {
      heightMedium: '26px',
    },
  },
}

// 响应式变量
const selectedChainId = ref(0)

// 可选择的区块链网络列表
const chains = [
  { label: 'Please select', value: 0 },
  { label: 'Ethereum Mainnet', value: 1 },
  { label: 'Polygon', value: 137 },
  { label: 'Arbitrum Sepolia', value: 421614 },
]

// 切换链的函数
const switchChain = async () => {
  try {
    if (!window.ethereum) {
      console.error('No Ethereum provider found')
      return
    }
    console.log(
      `0x${parseInt(selectedChainId.value).toString(16)}`,
      getCurrentProvider().request,
    )

    const data = await getCurrentProvider().request({
      method: 'wallet_switchEthereumChain',
      params: [
        { chainId: `0x${parseInt(selectedChainId.value).toString(16)}` },
      ],
    })

    console.log(`Switched to chain with ID: ${selectedChainId.value}`)
  } catch (error) {
    // 如果链不存在则添加新链
    if (error.code === 4902) {
      try {
        await getCurrentProvider().request({
          method: 'wallet_addEthereumChain',
          params: [
            {
              chainId: `0x${parseInt(selectedChainId.value).toString(16)}`,
              chainName: getChainName(selectedChainId.value),
              rpcUrls: getRpcUrls(selectedChainId.value),
              nativeCurrency: { name: 'ETH', symbol: 'ETH', decimals: 18 },
              blockExplorerUrls: getBlockExplorerUrls(selectedChainId.value),
            },
          ],
        })
        console.log(
          `Added and switched to chain with ID: ${selectedChainId.value}`,
        )
      } catch (addError) {
        console.error('Failed to add network:', addError)
      }
    } else {
      console.error('Failed to switch network:', error)
    }
  }
}

// 工具函数：获取链的名称、RPC URL 和区块浏览器 URL
function getChainName(chainId) {
  const chainNames = {
    1: 'Ethereum Mainnet',
    137: 'Polygon',
    42161: 'Arbitrum',
    500: 'Immutable zkEVM',
  }
  return chainNames[chainId]
}

function getRpcUrls(chainId) {
  const rpcUrls = {
    1: ['https://mainnet.infura.io/v3/YOUR_INFURA_PROJECT_ID'],
    137: ['https://polygon-rpc.com/'],
    42161: ['https://arb1.arbitrum.io/rpc'],
    500: ['https://api.zkevm.immutable.com'],
  }
  return rpcUrls[chainId]
}

function getBlockExplorerUrls(chainId) {
  const explorerUrls = {
    1: ['https://etherscan.io'],
    137: ['https://polygonscan.com'],
    42161: ['https://arbiscan.io'],
    500: ['https://explorer.zkevm.immutable.com'],
  }
  return explorerUrls[chainId]
}

// watch(selectedChainId, val => switchChain())
</script>

<template>
  <div class="wrap item_container">
    <Header></Header>
    <div class="main flex justify-center pt-6% relative">
      <div>
        <div class="flex">
          <div
            @click="handleSelectHero"
            class="mouse_pointer mb-8 mr-4 text-#E4D5B9 relative w-258px h-28px flex flex-items-center pl-12px bg-#0C0B09 border border-solid border-#494744/60 rounded-5px"
          >
            HERO
            <div
              class="absolute pl-9px left-66px right-0 top-0 bottom-0 leading-24px text-#A39883 bg-#1C1A19 border border-dashed border-#3F3C3A/60 rounded-5px"
            >
              {{ roleId ? roleName : 'Please select' }}
            </div>
          </div>
          <div
            class="mb-4 flex items-center md:mb-0 md:h-[28px] md:bg-#0c0b0a text-#E4D5B9 md:pl-2 border border-dashed border-#3F3C3A/60 rounded-5px"
          >
            <span class="mr-3 md:mr-2">NETWORK</span>
            <NSelect
              v-model:value="selectedChainId"
              :options="chains"
              :theme-overrides="selectTheme"
              style="width: 180px"
            ></NSelect>
          </div>
        </div>
        <div class="flex">
          <Card
            @buy="data => handleBuy(0, data, selectedChainId)"
            :productData="item"
            :key="item.id"
            v-for="item in productsList"
          ></Card>
        </div>
      </div>
    </div>
    <History @click="openHistoryModal"></History>
    <RolesModal
      :list="roleList?.slice(1)"
      :roleId="roleId"
      @confirm="handleConfirm"
      v-model:show="showRoleModal"
    ></RolesModal>
    <SuccessModal
      :list="successList"
      v-model:show="showSuccessModal"
    ></SuccessModal>
    <HistoryModal
      v-model:show="showHistoryModal"
      headerText="SOUL JADE STORE"
      hasNFT
      :type="2"
    ></HistoryModal>
  </div>
</template>

<style lang="scss" scoped>
.wrap {
  position: relative;
  width: 100vw;
  height: 100vh;
  overflow: hidden;

  @include bgAuto('src/asset/immutableIncome/bg.webp');

  background-position-y: 82px;

  .main {
    height: calc(100vh - var(--header-height));
  }
}
</style>
