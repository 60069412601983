<script setup>
import { ref } from 'vue'
import { NEmpty, useMessage } from 'naive-ui'
import { storeToRefs } from 'pinia'
import { useImmutableInfoStore } from 'src/stores/immutable'
import { errorCodeToMessage } from 'src/helpers/utils'
import { useRoleList } from 'src/pages/myCollection/hooks/useRoleList'
import { useBaseSelectData } from 'src/pages/myCollection/hooks/useBaseSelectData'
import { hasPhone } from 'src/stores/media'
import SoulBox from '../../components/SoulBox.vue'
import PanelImmutable from '../../components/PanelImmutable.vue'
import TransferImmutableModal from '../../components/TransferImmutableModal'
import Empty from '../../components/Empty'
import List from '../../components/List'
import ImmutableItemDetail from '../../components/ImmutableItemDetail'
import ImmutablePreviewModal from '../../components/ImmutablePreviewModal'
import LockBtns from '../../components/LockBtns'
import { useSoulList } from '../../hooks/useSoulList'
import { useLockImmutableSoulCard } from '../../hooks/useLockImmutableSoulCard'
import { idsToListItem } from '../../utils'

const immutableInfoStore = useImmutableInfoStore()
const { executeSoulCoreMarketplaceTnfoList, executeSoulCoreInfoList } =
  immutableInfoStore
const { soulCoreInfoMarketplaceList } = storeToRefs(immutableInfoStore)
const roleListRef = useRoleList()
const { soulListComputed, getHasLoading } = useSoulList(
  soulCoreInfoMarketplaceList,
  roleListRef,
)
const {
  selectDataRef,
  itemDetailShowRef,
  singleChoiceDataRef,
  previewDataRef,
  transferShowRef,
  currentSelectDataRef,
  previewShowRef,
  handleSelect,
  handleCancel,
  handleEnlarge,
  handleClose,
  handleCheck,
  handleEmptySelect,
  handleItemDetailClose,
} = useBaseSelectData()
const { isLoading: lockLoading, execute } = useLockImmutableSoulCard()
const message = useMessage()
const currentUserIDRef = ref()
async function handleConfirmUnlock() {
  try {
    const item = idsToListItem(
      currentSelectDataRef.value,
      soulListComputed.value,
    )
    await execute(0, item, currentUserIDRef.value)
    message.success('success')
  } catch (error) {
    console.log(error)
    errorCodeToMessage(error)
    executeSoulCoreMarketplaceTnfoList()
    executeSoulCoreInfoList()
  } finally {
    transferShowRef.value = false
  }
}
function handleLockId(userId, ids) {
  handleLock(userId, ids)
}

function handleLock(userId, ids) {
  const selectData = Array.isArray(ids)
    ? ids
    : selectDataRef.value.length
      ? selectDataRef.value
      : [singleChoiceDataRef.value.Id]
  if (!userId) {
    message.warning('Please select a hero.')
  } else if (!selectData) {
    message.warning('Please select an item.')
  } else {
    currentUserIDRef.value = userId
    currentSelectDataRef.value = selectData
    transferShowRef.value = true
  }
}
function handleDetails(data) {
  previewShowRef.value = true
  previewDataRef.value = data
}
</script>
<template>
  <div class="h-full flex">
    <PanelImmutable
      v-if="!hasPhone"
      class="flex-shrink-0"
      :data="singleChoiceDataRef"
      @details="handleDetails"
    >
    </PanelImmutable>
    <List v-if="soulListComputed.length" :list="soulListComputed" :box="false">
      <template #item="item">
        <SoulBox
          :data="item"
          :select-data="selectDataRef"
          :single-choice-data="singleChoiceDataRef"
          :role-list="roleListRef"
          @check="handleCheck"
          @select="handleSelect"
          @cancel="handleCancel"
          @enlarge="handleEnlarge"
          @submit="handleLockId"
        >
          <template #btn="{ handleSubmit }">
            <LockBtns
              @lock="handleSubmit"
              :type="item.type"
              :disabled="getHasLoading(item)"
              :responseWait="item.responseWait"
            ></LockBtns>
          </template>
        </SoulBox>
      </template>
    </List>
    <NEmpty v-else class="m-auto"></NEmpty>
    <Empty
      v-if="!hasPhone"
      v-show="selectDataRef.length"
      @click="handleEmptySelect"
    />
  </div>
  <ImmutableItemDetail
    v-if="hasPhone"
    v-show="itemDetailShowRef"
    :data="singleChoiceDataRef"
    :role-list="roleListRef"
    @close="handleItemDetailClose"
    @submit="handleLock"
  >
    <template #btn="{ handleSubmit }">
      <LockBtns
        @lock="handleSubmit"
        :type="singleChoiceDataRef?.type"
        :disabled="getHasLoading(singleChoiceDataRef)"
        :responseWait="singleChoiceDataRef?.responseWait"
      ></LockBtns>
    </template>
  </ImmutableItemDetail>
  <ImmutablePreviewModal
    v-model:show="previewShowRef"
    @close="handleClose"
    :data="previewDataRef"
    :poster="previewDataRef?.Icon"
    :src="previewDataRef?.animation_url"
    :type="previewDataRef?.animation_url ? 2 : 1"
  ></ImmutablePreviewModal>
  <TransferImmutableModal
    v-model:show="transferShowRef"
    :loading="lockLoading"
    text="Are you sure you want to lock this item?"
    @confirm="handleConfirmUnlock"
  ></TransferImmutableModal>
</template>

<style scoped lang="scss">
@import '../../border';
</style>
