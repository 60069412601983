import abi from 'src/sdk/blockchain/abi/AirdropToken.json'
import { SoulJadeStore } from '../soulJade'
import { storeToRefs } from 'pinia'
import { useUserInfoStore } from 'src/stores/user'
import { accountRef } from 'src/sdk/blockchain'
import { parseUnits } from 'viem'
class SoulJade extends SoulJadeStore {
  constructor() {
    super()
    this.setAbi(abi)
  }
  async mint(chainId, number) {
    console.log(chainId, number)
    // const { signature, expireTime } = await fetchPFPSig({
    //   address: accountRef.value?.address,
    //   phase: currentParamPhaseComputed.value,
    //   quantity: number,
    // })
    await super.mint({
      functionName: 'approve',
      contractAddress: '0x7F790e16821D2f53AB1CD23375B55DFDb8e9e014',
      accountAddress: accountRef.value?.address,
      chainId,
      args: ['0x53d0d72e80012ce864d1644d0f305b4574500fa3', number],
    })
  }
}

export const soulJade = new SoulJade()
