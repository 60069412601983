<script setup>
import { NSelect, NFormItem, useMessage, NSpin } from 'naive-ui'
import { ref, computed } from 'vue'
import { selectTheme } from 'src/pages/myCollection/utils'
import { useBenefitToken } from './index'
import BenefitModal from './benefitModal/index.vue'
import BenefitsItem from './components/BenefitsItem.vue'
import TokensInfo from './components/TokensInfo'
import MIntTokens from './components/MIntTokens.vue'
import History from './components/History'
import TokenHistoryModal from './components/TokenHistoryModal'
import BenefitsCard from './components/BenefitsCard'
import RolesModal from './components/RolesModal'
import SuccessModal from './components/SuccessModal'
import LoadingText from './components/LoadingText'
import LottieAnimation from 'src/components/Lottie.vue'
import closeImg from 'src/asset/close.webp'
import animationData from 'src/lottie/Circle.json'

const message = useMessage()
const {
  roleList,
  roleId,
  roleName,
  benefitsList,
  tokenInfoRef,
  tokenCounts,
  showHistoryModal,
  showSuccessModal,
  showRoleModal,
  counts,
  statusText,
  isOrderLoading,
  consumeTbogxList,
  executeData,
  handleClaim,
  handleReceive,
  handleConfirm,
  handleBindSuccess,
  handleSelectHero,
  executeWeb3Signed,
} = useBenefitToken()

const showBenefitModal = ref(false)
const activeIndex = ref(0)

function handleBuy(level) {
  if (!roleId.value) {
    message.warning('Please select a hero.')
    return
  }
  if (statusText.value === 'Claimed') {
    message.warning('The benefit has been claimed')
    return
  }
  handleReceive(level)
}

function handleDownload() {
  window.open('https://store.epicgames.com/en-US/p/bogx-a639f3', '_blank')
}
</script>
<template>
  <div class="main flex">
    <div class="w-290px border-0 border-r border-solid border-#404753">
      <div
        @click="showBenefitModal = true"
        class="btn-manage border border-solid border-#C29B6D mouse_pointer fz-5 flex-center w-full h-70px text-#EED095"
      >
        Manage Benefits
      </div>
      <img
        class="block w-full h-2px mt-0.5 mb-4"
        src="src/asset/nftCards/line.webp"
        alt=""
      />
      <div class="mx-4 w-full flex flex-col">
        <div
          @click="handleSelectHero"
          class="mouse_pointer text-#E4D5B9 relative w-258px h-28px flex flex-items-center pl-12px bg-#0C0B09 border border-solid border-#494744/60 rounded-5px"
        >
          HERO
          <div
            class="absolute pl-9px left-66px right-0 top-0 bottom-0 text-#E4D5B9 bg-#1C1A19 border border-dashed border-#3F3C3A/60 rounded-5px"
          >
            {{ roleId ? roleName : 'Please select' }}
          </div>
        </div>
      </div>
      <div class="md:px-4 mt-4">
        <ul class="scroll h-75vh overflow-y-auto">
          <li
            class="relative"
            v-for="item in benefitsList"
            @click="activeIndex = item.id"
          >
            <BenefitsItem
              :activeIndex="activeIndex"
              :data="item"
            ></BenefitsItem>
          </li>
        </ul>
      </div>
    </div>
    <div class="flex-1">
      <TokensInfo :data="tokenInfoRef"></TokensInfo>
      <div
        class="flex relative px-2.5% py-2% w-full md:h-600px 2xl:h-720px 5xl:h-1000px 6xl:h-1500px bg-#1D1815/70 border border-r-0 border-solid border-#544F4F"
      >
        <div class="w-28% pr-2.5% flex-center">
          <BenefitsCard
            :data="benefitsList[activeIndex]"
            @buy="handleBuy"
          ></BenefitsCard>
        </div>
        <div class="line opacity-40 w-1px h-full"></div>
        <div class="flex-1 flex flex-col justify-center">
          <MIntTokens
            :data="tokenInfoRef"
            @endTime="executeData(0, roleId)"
            @claim="handleClaim"
            @download="handleDownload"
          ></MIntTokens>
        </div>

        <!-- <div
          v-if="isOrderLoading"
          class="absolute top-0 left-0 z-1 h-full w-full"
        >
          <NSpin :size="150" class="absolute-center"></NSpin>
        </div> -->
        <transition name="fade">
          <div
            v-if="isOrderLoading"
            class="loading-bg flex-center text-1.25vw text-#EED095 tracking-2.4px absolute top-0 left-0 z-1 h-full w-full"
          >
            <div
              class="5xl:mt-40 2xl:mt-3 -md:mt-12 flex flex-col flex-items-center"
            >
              <div class="w-221.5px">
                <LottieAnimation
                  :animationData="animationData"
                  :loop="true"
                  :autoplay="true"
                ></LottieAnimation>
              </div>
              <LoadingText class="mt-2 mb-6"></LoadingText>
              <span
                >Claiming is about to be completed, please wait
                patiently...</span
              >
              <div class="mt-8 flex flex-items-center">
                <img
                  class="w-69.5px mr-4 -mt-4"
                  src="src/asset/nftCards/vbogx.webp"
                  alt=""
                />
                <span>$tBOGX: {{ tokenInfoRef?.v_bo_gx_unlock_count }}</span>
              </div>
            </div>
          </div>
        </transition>

        <transition name="fade">
          <div
            v-if="showSuccessModal"
            class="success-bg text-1.25vw text-#EED095 tracking-2.4px absolute top-0 left-0 z-1 h-full w-full"
          >
            <img
              :src="closeImg"
              @click="showSuccessModal = false"
              class="w-24px absolute right-4 top-4 mouse_pointer"
            />
            <div class="mt-76px text-center">
              -UNLOCKING COMPLETED! CONGRATULATIONS ON GETTING-
            </div>
            <div class="absolute bottom-14% left-50% -translate-x-50%">
              $tBOGX: {{ counts }}
            </div>
          </div>
        </transition>
      </div>
    </div>

    <History
      @history="showHistoryModal = true"
      class="absolute right-2vw bottom-1vw"
    ></History>
    <BenefitModal
      :roleList="roleList"
      @success="handleBindSuccess"
      v-model:show="showBenefitModal"
    ></BenefitModal>
    <TokenHistoryModal
      v-model:show="showHistoryModal"
      :counts="tokenCounts"
      :list="consumeTbogxList"
    >
    </TokenHistoryModal>

    <RolesModal
      :list="roleList?.slice(1)"
      :roleId="roleId"
      @confirm="handleConfirm"
      v-model:show="showRoleModal"
    ></RolesModal>
  </div>
</template>

<style scoped lang="scss">
.main {
  height: calc(100vh - var(--header-height));

  .btn-manage {
    background: linear-gradient(
      180deg,
      #342110 0%,
      #342110 21.07%,
      #24180f 76.59%,
      #3a342d 100%
    );
    border: 1px solid #c29b6d;
    border-radius: 5px;

    &:hover {
      background: linear-gradient(
        180deg,
        #4e3016 0%,
        #4e3016 21.07%,
        #442e1c 76.59%,
        #796141 100%
      );
    }
  }

  .line {
    background: linear-gradient(
      180deg,
      #14110f 0%,
      #716857 46.95%,
      #14110f 100%
    );
  }

  .success-bg {
    background-color: #000;

    @include bg100('src/asset/nftCards/success_bg.webp');
  }

  .loading-bg {
    background-color: #000;

    @include bg100('src/asset/nftCards/loading_bg.webp');
  }
}

.loading {
  transform-origin: 50% 50%;

  // animation: spin 1s linear infinite;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.scroll {
  &::-webkit-scrollbar {
    /* 滚动条整体样式 */
    width: 0; /* 高宽分别对应横竖滚动条的尺寸 */
    height: 0;
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.2s;
}

.fade-enter, .fade-leave-to /* .fade-leave-active in <2.1.8 */ {
  opacity: 0;
}
</style>
