<script setup>
import Modal from 'src/components/Modal'
import BaseList from 'src/components/BaseList.vue'
import eggImg from 'src/asset/PFP/egg.webp'

const props = defineProps({
  show: Boolean,
  mintList: {
    type: Array,
    default: [],
  },
})
const emit = defineEmits(['update:show'])

const getAssetsFile = url => {
  return new URL(`/src/asset/soulCardMint/cards/${url}.webp`, import.meta.url)
    .href
}

const cardsList = [
  {
    name: 'Brynhild. the Solemn',
    type: 'orange',
    image: getAssetsFile('card1'),
  },
  {
    name: 'Sigurd. the Dragonslayer',
    type: 'orange',
    image: getAssetsFile('card2'),
  },
  {
    name: 'Skoll. the Frost Hunter',
    type: 'gold',
    image: getAssetsFile('card3'),
  },
  {
    name: 'Hela. the Shadow Dancer',
    type: 'gold',
    image: getAssetsFile('card4'),
  },
  {
    name: 'Hati. the Lost Immolator',
    type: 'gold',
    image: getAssetsFile('card5'),
  },
  {
    name: 'Esther. the Death Whisper',
    type: 'gold',
    image: getAssetsFile('card6'),
  },
  {
    name: 'Hela. the Soul Reaper',
    type: 'gold',
    image: getAssetsFile('card7'),
  },
  {
    name: 'Hela. the Executer',
    type: 'purple',
    image: getAssetsFile('card8'),
  },
  {
    name: 'Huginn. the Watcher',
    type: 'purple',
    image: getAssetsFile('card9'),
  },
  {
    name: 'Esther. the Hunter',
    type: 'purple',
    image: getAssetsFile('card10'),
  },
  {
    name: 'Ein. the Minotaur',
    type: 'purple',
    image: getAssetsFile('card11'),
  },
  {
    name: 'Randgrith. the Shieldbreaker',
    type: 'purple',
    image: getAssetsFile('card12'),
  },
  {
    name: 'Muninn. the Messenger',
    type: 'purple',
    image: getAssetsFile('card13'),
  },
]
function handleClose() {
  emit('update:show', false)
}
</script>

<template>
  <Modal :show="props.show" @update:show="handleClose">
    <div
      class="flex flex-col flex-items-center bg-#090919 border border-solid border-#5A526A/60 rounded-5px"
    >
      <div
        class="fz-6 flex-center tracking-1.5px relative w-full h-50px bg-#101028 text-#AA8BF1 border border-solid border-#5A526A/60"
      >
        SOUL CARDS LIST
        <img
          @click="handleClose"
          class="mouse_pointer w-30px absolute right-4 top-2"
          src="src/asset/soulCardMint/close.webp"
          alt=""
        />
      </div>
      <div class="flex-center py-10 flex-wrap w-96vw h-90vh overflow-auto">
        <BaseList
          :list="cardsList"
          :xGap="26"
          :yGap="26"
          :number2xl="5"
          :numberMD="4"
        >
          <template #item="item">
            <div
              :class="
                item.type === 'orange'
                  ? 'border-#EF7F48/60 text-#F99043'
                  : item.type === 'gold'
                    ? 'border-#D49536 text-#F9B743'
                    : 'border-#8B3FCF text-#B243F9'
              "
              class="bg-#241E1C flex flex-col w-299px h-374px border border-solid rounded-8px overflow-hidden"
            >
              <img
                class="w-full h-299px rounded-8px"
                :src="item.image"
                alt=""
              />
              <div class="flex-1 flex-center flex-col">
                <div class="fz-4 text-#fff">SOUL CARD</div>
                <div class="fz-4">{{ item.name }}</div>
              </div>
            </div>
          </template>
        </BaseList>
      </div>
    </div>
  </Modal>
</template>
