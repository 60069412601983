import { useAsyncState } from '@vueuse/core'
import { fetchGameMint } from 'src/api'
import { getSoulToknDataId } from 'src/pages/myCollection/utils'
import { ref } from 'vue'
import { fetchAuth } from 'src/sdk/immutable'
import { getBaseWalletSign } from 'src/helpers/blockchain'
import { storeToRefs } from 'pinia'
import { useImmutableInfoStore } from 'src/stores/immutable'
import { AppHttpCode } from 'src/constant'

export function useMint() {
  const immutableInfoStore = useImmutableInfoStore()
  const { executeImmutableInfo } = immutableInfoStore
  const { immutableCurrentBindAccount, sbtInfo } =
    storeToRefs(immutableInfoStore)
  const { execute: executeGameMint, isLoading: loadingGameMint } =
    useAsyncState(
      async item => {
        await fetchAuth()
        const web3Signed = await getBaseWalletSign(
          immutableCurrentBindAccount.value,
          sbtInfo.chainId,
        )
        const itemToken = getSoulToknDataId(item)
        const data = await fetchGameMint({
          itemToken,
          web3Signed,
        })
        return data
      },
      null,
      {
        immediate: false,
        onError(error) {
          if (error?.data?.code === AppHttpCode.BOGX_TOKEN_NOT_ENOUGH) {
            insufficientFundsRef.value = true
            executeImmutableInfo()
          }
          throw error
        },
      },
    )
  const insufficientFundsRef = ref(false)

  return {
    executeGameMint,
    loadingGameMint,
    insufficientFundsRef,
  }
}
