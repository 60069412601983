import { storeToRefs } from 'pinia'
import { usePFPMintStore } from 'src/stores/pfp'
import { computed } from 'vue'
export function useTime() {
  const pfpStore = usePFPMintStore()
  const { getPFPMintInfo } = pfpStore
  const {
    mintInfo: mintInfoRef,
    phaseComputed,
    wholeActivityStatusComputed,
  } = storeToRefs(pfpStore)
  const serverTimeComputed = computed(() => 1728545760 * 1000)
  const startTimeComputed = computed(() => {
    const phase = phaseComputed.value

    // 未开始
    if (phase === 0) {
      return mintInfoRef.value?.gtdTime[0] * 1000
    } else if (phase === 1) {
      // 第一阶段
      return mintInfoRef.value?.gtdTime[1] * 1000
    } else if (phase === 2) {
      // 第二阶段
      return mintInfoRef.value?.fcfsTime[1] * 1000
    } else {
      // 结束
      return mintInfoRef.value?.fcfsTime[1] * 1000
    }
  })
  const endTimeComputed = computed(() => {
    return startTimeComputed.value
    // const phase = phaseComputed.value
    // const data = mintInfoRef.value
    // if (phase) {
    //   return data?.timeline[Math.max(phase, data.timeline.length - 1)] * 1000
    // }
  })
  function handleTimeEnd() {
    if (wholeActivityStatusComputed.value !== 3) {
      getPFPMintInfo()
    }
  }
  return {
    serverTimeComputed,
    startTimeComputed,
    endTimeComputed,
    handleTimeEnd,
  }
}
